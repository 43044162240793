.download:hover {
  color: #000;
  /* background-color: #0000ff40; */
  /* background-color: #0000ff25; */
  background-color: #f9d3bc9a;
}

.download:active {
  color: #000;
  /* background-color: #0000ff4d; */
  /* background-color: #0000ff40; */
  background-color: #f9d3bc;
}

.sideOptions {
  background-color: #fff;
}

.sideOptions:active {
  background-color: #0000ff40;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.calendar {
  border: 1px solid red;
  /* width: 100vw; */
}

.dateControls {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  align-items: center;

  /* ion-icon {
    font-size: 1.6rem;
    cursor: pointer;
  } */
}

/* .sevenColGrid{

} */

.mainContent {
  display: grid;
  /* height: 50px; */
  text-align: center;
  font-size: 1rem;
  font-weight: 550;
  border: 0.5px solid #8d7474;
}

.checkIn {
  font-size: 0.8rem;
  width: 100px;
  cursor: pointer;
}

.checkOut {
  font-size: 0.8rem;
  width: 100px;
  cursor: pointer;
  background-color: #fff;
}

/* .fp-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #5e5e5ebb;

}

.fp-container .fp-loader {
    top: 45%;
    left: 45%;
    z-index: 1000;
    position: absolute;
}

.fp-loader-txt {
    text-align: justify;
    justify-content: center;
    top: 50%;
    left: 35%;
    margin-top: 30px;
    font-size: large;
    font-weight: 600;
    z-index: 1000;
    position: absolute;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

    .fp-container .fp-loader {
        top: 30%;
        left: 25%;
        z-index: 1000;
        position: absolute;
    }

    .fp-loader-txt {
        text-align: justify;
        justify-content: center;
        top: 50%;
        left: 15%;
        right: 10%;
        margin-top: 30px;
        font-size: large;
        font-weight: 600;
        z-index: 1000;
        position: absolute;
    }
} */

.fp-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #edebebbf;
    /* background-color: #ffffffdc; */
}

.main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#wifi-loader {
    --background: #62abff;
    --front-color: blue;
    --back-color: #c8c9ca95;
    --text-color: #c8c9ca;
    width: 64px;
    height: 64px;
    border-radius: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#wifi-loader svg {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

#wifi-loader svg circle {
    position: absolute;
    fill: none;
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transform: rotate(-100deg);
    transform-origin: center;
}

#wifi-loader svg circle.back {
    stroke: var(--back-color);
}

#wifi-loader svg circle.front {
    stroke: blue;
}

#wifi-loader svg.circle-outer {
    height: 86px;
    width: 86px;
}

#wifi-loader svg.circle-outer circle {
    stroke-dasharray: 62.75 188.25;
}

#wifi-loader svg.circle-outer circle.back {
    animation: circle-outer135 1.8s ease infinite 0.3s;
}

#wifi-loader svg.circle-outer circle.front {
    animation: circle-outer135 1.8s ease infinite 0.15s;
}

#wifi-loader svg.circle-middle {
    height: 60px;
    width: 60px;
}

#wifi-loader svg.circle-middle circle {
    stroke-dasharray: 42.5 127.5;
}

#wifi-loader svg.circle-middle circle.back {
    animation: circle-middle6123 1.8s ease infinite 0.25s;
}

#wifi-loader svg.circle-middle circle.front {
    animation: circle-middle6123 1.8s ease infinite 0.1s;
}

#wifi-loader svg.circle-inner {
    height: 34px;
    width: 34px;
}

#wifi-loader svg.circle-inner circle {
    stroke-dasharray: 22 66;
}

#wifi-loader svg.circle-inner circle.back {
    animation: circle-inner162 1.8s ease infinite 0.2s;
}

#wifi-loader svg.circle-inner circle.front {
    animation: circle-inner162 1.8s ease infinite 0.05s;
}

#wifi-loader .text {
    font-family: monospace;
    position: absolute;
    bottom: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: lowercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 2px;
    margin-top: 5px;
}

#wifi-loader .text::before,
#wifi-loader .text::after {
    content: attr(data-text);
}

#wifi-loader .text::before {
    color: var(--text-color);
}

#wifi-loader .text::after {
    color: var(--front-color);
    animation: text-animation76 3.6s ease infinite;
    position: absolute;
    left: 0;
}

@keyframes circle-outer135 {
    0% {
        stroke-dashoffset: 25;
    }

    25% {
        stroke-dashoffset: 0;
    }

    65% {
        stroke-dashoffset: 301;
    }

    80% {
        stroke-dashoffset: 276;
    }

    100% {
        stroke-dashoffset: 276;
    }
}

@keyframes circle-middle6123 {
    0% {
        stroke-dashoffset: 17;
    }

    25% {
        stroke-dashoffset: 0;
    }

    65% {
        stroke-dashoffset: 204;
    }

    80% {
        stroke-dashoffset: 187;
    }

    100% {
        stroke-dashoffset: 187;
    }
}

@keyframes circle-inner162 {
    0% {
        stroke-dashoffset: 9;
    }

    25% {
        stroke-dashoffset: 0;
    }

    65% {
        stroke-dashoffset: 106;
    }

    80% {
        stroke-dashoffset: 97;
    }

    100% {
        stroke-dashoffset: 97;
    }
}

@keyframes text-animation76 {
    0% {
        clip-path: inset(0 100% 0 0);
    }

    50% {
        clip-path: inset(0);
    }

    100% {
        clip-path: inset(0 0 0 100%);
    }
}
.fc-daygrid-body-unbalanced {
  position: relative;
  min-width: none;
  min-height: none;
}
.frrr {
  /* background-color: red; */
    color: #000;
    height: 100vh;
    min-height: 100vh;
}

.calendar{
    height: 50vh;
    max-height: 50vh;
}

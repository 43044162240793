.orbit {
    position: relative;
    top: 0px;
    padding-top: 00px;
    right: -20px
}

.orbit .main-orbit {
    /* background-image: radial-gradient(circle, white, blue); */
    box-shadow: 0 0 64px -17px #86abb9b3;
    width: 440px;
    height: 440px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%
}

.orbit .main-orbit .img_inner {
    width: 180px;
    height: 180px;
    background: #f4f9fe;
    /* background: red; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center
}

.orbit .main-orbit .img_inner img {
    width: 90px
}

.orbit .start-or {
    position: absolute;
    right: 50px
}

.orbit .sm-circle {
    width: 180px;
    height: 180px;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #f1f1f1;
    animation: shadow-spread 2s infinite
}

.orbit .mid-circle {
    width: 300px;
    height: 300px;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0 0 0 70px #f4f9fe
}

.orbit .outer-circle {
    width: 420px;
    height: 420px;
    position: absolute;
    border-radius: 50%
}

.orbit .outer_text {
    position: absolute
}

.orbit .outer_text .circle_child {
    position: absolute;
    font-size: 18px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    animation: popup 1s infnite;
    animation: spin_clock_counter 200s infinite linear
}

.orbit .outer_text .circle_child:before {
    color: var(--thm-dark);
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -30px;
    font-weight: 100;
    font-size: 20px;
    font-weight: 400;
    white-space: nowrap
}

.orbit .outer_text .circle_child:after {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -18px;
    font-size: 14px;
    color: #000 !important;
    font-weight: 100;
    white-space: nowrap
}

.orbit .outer_text .scrollme:nth-child(1) .circle_child {
    background-color: pink;
    top: -218px;
    left: 40px;
    right: 0
}

.orbit .outer_text .scrollme:nth-child(1) .circle_child:before {
    content: "HRMS"
}

/* .orbit .outer_text .scrollme:nth-child(1) .circle_child:after {
    content: "Know Your Employees "
} */

.orbit .outer_text .scrollme:nth-child(2) .circle_child {
    background-color: yellow;
    bottom: -219px;
    left: 50px;
    right: 0
}

.orbit .outer_text .scrollme:nth-child(2) .circle_child:before {
    content: "CRM "
}

/* .orbit .outer_text .scrollme:nth-child(2) .circle_child:after {
    content: "Healthy Customer Bonds"
} */

.orbit .outer_text .scrollme:nth-child(3) .circle_child {
    background-color: blue;
    left: -163px;
    margin: auto
}

.orbit .outer_text .scrollme:nth-child(3) .circle_child:before {
    content: "Lead Management"
}

/* .orbit .outer_text .scrollme:nth-child(3) .circle_child:after {
    content: "Align your Leaves/Schedule"
} */

.orbit .outer_text .scrollme:nth-child(4) .circle_child {
    background-color: skyblue;
    right: -163px;
    margin: auto
}

.orbit .outer_text .scrollme:nth-child(4) .circle_child:before {
    content: "Client Management"
}

/* .orbit .outer_text .scrollme:nth-child(4) .circle_child:after {
    content: "Stay Connected "
} */

.orbit .outer_text .scrollme:nth-child(5) .circle_child {
    background-color: orange;
    left: -110px;
    top: -160px
}

.orbit .outer_text .scrollme:nth-child(5) .circle_child:before {
    content: "Firm Management"
}

/* .orbit .outer_text .scrollme:nth-child(5) .circle_child:after {
    content: "Lead The Manpower"
} */

.orbit .outer_text .scrollme:nth-child(6) .circle_child {
    background-color: green;
    right: -105px;
    top: -150px
}

.orbit .outer_text .scrollme:nth-child(6) .circle_child:before {
    content: "Attendance"
}

/* .orbit .outer_text .scrollme:nth-child(6) .circle_child:after {
    content: "Manage The Hardware"
} */

.orbit .outer_text .scrollme:nth-child(7) .circle_child {
    background-color: brown;
    right: -100px;
    bottom: -155px
}

.orbit .outer_text .scrollme:nth-child(7) .circle_child:before {
    content: "Performance"
}

/* .orbit .outer_text .scrollme:nth-child(7) .circle_child:after {
    content: "Track, Appraise & Feedbacks"
} */

.orbit .outer_text .scrollme:nth-child(8) .circle_child {
    background-color: red;
    left: -115px;
    bottom: -155px
}

.orbit .outer_text .scrollme:nth-child(8) .circle_child:before {
    content: "Invoice"
}

/* .orbit .outer_text .scrollme:nth-child(8) .circle_child:after {
    content: "Socialize within your Org"
} */

.orbit .outer_text .scrollme1:nth-child(1) .circle_child {
    background-color: pink;
    top: -229px;
    left: -10px;
    right: 0
}

.orbit .outer_text .scrollme1:nth-child(1) .circle_child:before {
    content: "Students"
}

.orbit .outer_text .scrollme1:nth-child(1) .circle_child:after {
    content: "Handy Student details "
}

.orbit .outer_text .scrollme1:nth-child(2) .circle_child {
    background-color: yellow;
    bottom: -229px;
    left: -10px;
    right: 0
}

.orbit .outer_text .scrollme1:nth-child(2) .circle_child:before {
    content: "Teachers "
}

.orbit .outer_text .scrollme1:nth-child(2) .circle_child:after {
    content: "Staff details on deck"
}

.orbit .outer_text .scrollme1:nth-child(3) .circle_child {
    background-color: blue;
    left: -228px;
    margin: auto
}

.orbit .outer_text .scrollme1:nth-child(3) .circle_child:before {
    content: "Admission"
}

.orbit .outer_text .scrollme1:nth-child(3) .circle_child:after {
    content: "Hasslefree admissions "
}

.orbit .outer_text .scrollme1:nth-child(4) .circle_child {
    background-color: skyblue;
    right: -228px;
    margin: auto
}

.orbit .outer_text .scrollme1:nth-child(4) .circle_child:before {
    content: "Lead Management"
}

/* .orbit .outer_text .scrollme1:nth-child(4) .circle_child:after {
    content: "Error-free Turnouts "
} */

.orbit .outer_text .scrollme1:nth-child(5) .circle_child {
    background-color: orange;
    left: -176px;
    top: -150px
}

.orbit .outer_text .scrollme1:nth-child(5) .circle_child:before {
    content: "Live classes"
}

.orbit .outer_text .scrollme1:nth-child(5) .circle_child:after {
    content: "Real-time home learning"
}

.orbit .outer_text .scrollme1:nth-child(6) .circle_child {
    background-color: green;
    right: -176px;
    top: -150px
}

.orbit .outer_text .scrollme1:nth-child(6) .circle_child:before {
    content: "Assignments"
}

.orbit .outer_text .scrollme1:nth-child(6) .circle_child:after {
    content: "Paperless Assignments"
}

.orbit .outer_text .scrollme1:nth-child(7) .circle_child {
    background-color: brown;
    right: -172px;
    bottom: -155px
}

.orbit .outer_text .scrollme1:nth-child(7) .circle_child:before {
    content: "Exams"
}

.orbit .outer_text .scrollme1:nth-child(7) .circle_child:after {
    content: "Digitalized assessments "
}

.orbit .outer_text .scrollme1:nth-child(8) .circle_child {
    background-color: red;
    left: -172px;
    bottom: -155px
}

.orbit .outer_text .scrollme1:nth-child(8) .circle_child:before {
    content: "Results"
}

.orbit .outer_text .scrollme1:nth-child(8) .circle_child:after {
    content: "E-bulletin for results"
}


@keyframes pulse_2 {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(250, 70, 123, 0.7)
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(250, 70, 123, 0)
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(250, 70, 123, 0.7)
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 20%, 0)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInUp {
    animation-name: fadeInUp
}

@keyframes borderColor {
    0% {
        border-color: rgba(250, 70, 123, 0.34)
    }

    20% {
        transform: rgba(108, 48, 235, 0.34)
    }

    40% {
        border-color: rgba(117, 197, 252, 0.34)
    }

    60% {
        border-color: rgba(252, 232, 173, 0.68)
    }

    80% {
        border-color: rgba(79, 142, 252, 0.34)
    }

    100% {
        border-color: rgba(250, 70, 123, 0.34)
    }
}

@keyframes popup {
    0% {
        transform: scale(0.9)
    }

    50% {
        transform: scale(1)
    }

    100% {
        transform: scale(0.9)
    }
}

@keyframes shadow-spread {
    0% {
        box-shadow: 0 0 0 0px #ccc
    }

    50% {
        box-shadow: 0 0 0 10px rgba(117, 197, 252, 0.6)
    }

    100% {
        box-shadow: 0 0 0 0px #ccc
    }
}

@keyframes lr {
    0% {
        left: 340px
    }
}

@keyframes pulse_2 {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(250, 70, 123, 0.7)
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(250, 70, 123, 0)
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(250, 70, 123, 0.7)
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 20%, 0)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInUp {
    animation-name: fadeInUp
}

@keyframes borderColor {
    0% {
        border-color: rgba(250, 70, 123, 0.34)
    }

    20% {
        transform: rgba(108, 48, 235, 0.34)
    }

    40% {
        border-color: rgba(117, 197, 252, 0.34)
    }

    60% {
        border-color: rgba(252, 232, 173, 0.68)
    }

    80% {
        border-color: rgba(79, 142, 252, 0.34)
    }

    100% {
        border-color: rgba(250, 70, 123, 0.34)
    }
}

@keyframes popup {
    0% {
        transform: scale(0.9)
    }

    50% {
        transform: scale(1)
    }

    100% {
        transform: scale(0.9)
    }
}

@keyframes shadow-spread {
    0% {
        box-shadow: 0 0 0 0px #ccc
    }

    50% {
        box-shadow: 0 0 0 10px rgba(117, 197, 252, 0.6)
    }

    100% {
        box-shadow: 0 0 0 0px #ccc
    }
}

@keyframes lr {
    0% {
        left: 340px
    }

    50% {
        left: 380px
    }

    100% {
        left: 340px
    }

    50% {
        left: 380px
    }

    100% {
        left: 340px
    }
}

.clock-spin {
    animation: spin_clock 200s infinite linear
}

.clock-spin_counter {
    animation: spin_clock_counter 200s infinite linear
}

@keyframes spin_clock {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin_clock_counter {
    0% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}
#invoice-icons{
    border: none;
    color: white;
    padding: 0.3rem 1rem;
    width: 4.5rem;
    text-align: center;
    background-color: #fff3;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

#invoice-icons:hover{
    /* background-color: #fff7;
    box-shadow: 0px 0px 10px 2px rgba(161, 170, 190, 0.642); */
    background-color: transparent;
}

.crm-shared-logo{
    width: 20%;
    height: 10%;
}

@media screen and (max-width: 768px) {
    .crm-shared-logo{
        width: 100px !important;
        height: 20px !important;
    }
}
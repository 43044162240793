.deleteMsg{
  color: #c03221;
}

.deleteMsg:hover{
  text-decoration: underline;
}

.yesbtn {
  background: #fff;
  cursor: pointer;
  border: 1px solid #c03221;
  padding: 0.1rem 1rem;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.yesbtn:hover {
  background: #c03221;
  color: #fff;
}

.nobtn {
  background: #fff;
  cursor: pointer;
  border: 1px solid #3a57e8;
  padding: 0.1rem 1rem;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.nobtn:hover {
  background: #3a57e8;
  color: #fff;
}

input[type="radio"],
input[type="checkbox"] {
    accent-color: green !important;
}

.invoiceInput::placeholder {
    color: #000;
    opacity: 1;
    /* Firefox */
}

.invoiceNumberInput::placeholder {
    color: #3a57e8;
    opacity: 1;
    /* Firefox */
}

.addTaxModal {
    margin-top: 10%;
    margin-left: 20%;
}

.desktop-view {
    width: 960px;
}

@media (min-width: 900px) {
    .desktop-view {
        width: 100vw;
        height: 100%;
    }
}

@media (max-width: 200px) {
    .desktop-view {
        width: auto;
        height: 100%;
    }
}

@media screen and (min-width: 2044px) {
    .addTaxModal {
        margin-top: 2%;
        margin-left: 10%;
    }
}

@media screen and (min-width: 3000px) {
    .addTaxModal {
        margin-top: 2%;
        margin-left: 5%;
    }
}
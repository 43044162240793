.testInput {
  position: relative;
  /* width: "10px"; */
  height: "20px";
  margin-left: 0;
  width: 50%;
  border: none;
  border-bottom: 1px solid cornflowerblue;
  border-radius: 0.5rem 0.5rem 0 0;
  /* box-shadow: 0px 0px 10px 1px rgb(193, 213, 226); */
}

.testInput:focus {
  /* border: 1px solid cornflowerblue; */
  box-shadow: 0px 0px 10px 1px rgb(193, 213, 226);
}

.testingClass {
  left: -100px;
  width: "60%";
  height: "10px";
  position: relative;
  animation-name: example;
  animation-duration: 2s;
  animation-delay: 0s;
}

@keyframes example {
  0% {
    right: -20px;
  }
}

.activit-bar {
  /* background-color: #060b26; */
  height: 80px;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 2px 10px 0px rgb(183, 183, 186);
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.activity-menu {
  /* background-color: rgb(144, 173, 251); */
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgb(183, 183, 186);
  width: 250px;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 100%;
  transition: 850ms;
  margin-top: 10px;
  margin-bottom: 10px;
  /* border-radius: 1rem; */
  z-index: 999;
}

.activity-menu.active {
  left: 60%;
  transition: 350ms;
}

.activity-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.activity-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex; 
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.activityv-text a:hover {
  background-color: #1a83ff;
}

.activity-menu-items {
  width: 100%;
}

.activitybar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

#leadSearchBox {
  color: #000;
  /* background-color: rgb(63, 78, 248); */
  background-color: rgb(246, 247, 248);
  position: absolute;
  width: 10rem;
  /* border: 1px solid blue; */
  /* height: 5rem; */
  /* padding: 1rem; */
  /* box-shadow: 3px 4px 10px 0px rgba(133, 156, 211, 0.75); */
  box-shadow: 0px 2px 10px 2px rgba(170, 170, 185, 0.874);
}

#leadSearchBox #leadBtn {
  font-size: 0.6rem;
  color: #000;
  background: #fff;
  /* border: 1px solid #00308b; */
  border: none;
  box-shadow: 0px 2px 10px 0px rgba(217, 217, 223, 0.874);
  transition: all 0.2s ease-in-out;
  padding: 0.2rem 0.5rem;
  margin-bottom: 1rem;
}

#leadSearchBox #leadBtn:hover {
  /* color: #fff; */
  /* background: #00308b; */
  box-shadow: 0px 2px 10px 0px rgba(170, 170, 185, 0.874);
  border: none;
}

#leadSearchInput {
  display: inline;
  color: #000;
  font-size: 0.8rem;
  width: 90%;
  /* margin-left: 5%; */
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(217, 217, 223, 0.874);
  /* position: absolute; */
}

#leadSearchInput:focus {
  /* border: 1px solid #031c4c; */
  /* box-shadow: 3px 4px 10px 0px rgba(133, 156, 211, 0.75); */
  box-shadow: 4px 7px 10px -6px rgba(197, 197, 197, 0.895);
}

#leadSearchById {
  color: #000;
  font-size: 0.8rem;
  width: 60%;
  display: inline;
  /* margin-left: 5%; */
  padding: 0.2rem 0.5rem;
  border: 1px solid #81a8f1;
  background-color: #fff;
  border-radius: 0.5rem;
  margin-bottom: 5px;
  /* position: absolute; */
  /* transition: all 2s ease-in-out; */
}

#leadSearchById:focus {
  border: 1px solid #031c4c;
  box-shadow: 3px 4px 10px 0px rgba(133, 156, 211, 0.75);
}

#ldeadSeaerchIcon {
  font-size: 0.8rem;
  cursor: pointer;
  margin-left: 5px;
}

#leadActionsList {
  position: absolute;
  font-size: 0.8rem;
  font-weight: 300;
  /* width: 5rem; */
  /* padding: 0.5rem 1rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  /* box-shadow: 3px 4px 10px 0px rgba(133, 156, 211, 0.75); */
  /* box-shadow: 0px 2px 10px 0px rgba(184, 184, 207, 0.874); */
  box-shadow: 0px 2px 10px 2px rgba(170, 170, 185, 0.874);
}

#leadActionsList #actionItems {
  width: 100%;
  list-style-type: none;
  text-align: center;
  padding: 0.3rem 1rem;
  cursor: pointer;
  border-bottom: 1px solid rgb(158, 158, 161);
  transition: all 0.2s ease-in-out;
}

#leadActionsList #actionItems:hover {
  color: red;
  /* background-color: blue; */
}
#stage {
  padding-top: 8px;
  text-align: center;
  /* background: #b8baf9; */
  height: 30px;
  width: 30px;
  cursor: pointer;
}

#nav-item {
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-align: center;
  /* border-bottom: 1px solid #00308b; */
  padding: 0.7rem;
  transition: all 0.3s ease-in-out;
}

#leadFormInput {
  color: #000;
  font-size: 0.8rem;
  width: "100%";
  padding: 1rem 0.5rem;
  margin: 0.2rem auto;
}

/* #leadFormInput:focus {
  border: 1px solid #031c4c;
  box-shadow: 3px 4px 10px 0px rgba(133, 156, 211, 0.75);
  box-shadow: 3px 4px 10px 0px rgba(155, 173, 211, 0.509);
} */

#lead-search {
  width: 50vw;
  padding: auto 0.7rem;
  border: none;
  border-radius: 100px;
  /* margin: 2% auto auto auto; */
  /* background-color: white; */
  /* color: #000; */
}

.form-group{
    margin-bottom: $spacer;
    .input-group-text {
        color: $gray-900;
    }
}
.form-group-alt{
    &.input-group{
        box-shadow: $box-shadow-sm;
        border-radius: $border-radius-sm;
        .input-group-text{
            color: $gray-900;
        }
        > :not(:first-child):not(.dropdown-menu){
            box-shadow: unset;
            &.input-group-text {
                border: 0;
                padding-left: 0;
            }
        }
        > :not(:last-child):not(.dropdown-menu){
            box-shadow: unset;
            padding-right: 0;
            &.input-group-text {
                border: 0;
            }
        }
        &:focus-within {
            box-shadow: $component-active-shadow;
        }
    }
    .form-control {
        border: 0;
        box-shadow: $box-shadow-sm;
        &.is-valid{
            box-shadow: $component-success-shadow;
            &:focus{
                box-shadow: $component-active-shadow;
            }
        }
        &.is-invalid{
            box-shadow: $component-error-shadow;
            &:focus{
                box-shadow: $component-active-shadow;
            }
        }
        &:focus{
            box-shadow: $component-active-shadow;
        }
    }
}

.form-select{
    box-shadow: none;
    &:focus{

        box-shadow: none;
    }
}

@mixin noUi-varient($selector, $value) {
    #{$selector} {
        .noUi-base {
            background: tint-color($value, 90%);
            .noUi-connect{
                background: $value;
            }
        }
        &.noUi-horizontal {
            .noUi-handle {
                border-color: $value;
                @if $noUi-track-shadow == true {
                    box-shadow: $noUi-box-color-shadow rgba($value, 1);
                    &:hover {
                        box-shadow: $noUi-box-color-shadow rgba($value, 1), $noUi-box-color-shadow-hover rgba($value, 1);   
                    }
                }
            }
        }
    }
}
a{
    color: var(--#{$variable-prefix}primary);
}
a:hover{
    color: var(--#{$variable-prefix}primary);
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
    color: var(--#{$variable-prefix}primary-shade-80);
}

body{
    // background-color: var(--bs-body-bg);
    // background-color: #81828226;
    // background-color: #dfdfdf;
    // background-color: #e7e7e773;
    // background-color: #e9e9e973;
    background-color: #efeeee;
}
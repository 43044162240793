.step-progress-bar {
    width: 90vw;
    height: 50px;
    /* background-color: #f5f5f5; */
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.step-progress-bar2 {
    width: 90vw;
    height: 50px;
    /* background-color: #f5f5f5; */
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.progressBar {
    height: 4px;
    margin-top: 1.5rem;
    width: 80vw;
    /* background-color: #06d706; */
    background-color: #ffa408;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.3s ease-in-out;
}

.steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: absolute;
    width: 90vw;
}

.step {
    /* flex: 1; */
    width: 2rem;
    height: 2rem;
    font-size: 0.9rem;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    color: #000;
    padding: 5px;
    /* border: 1px solid #06d706; */
    border: 1px solid #ffa408;
    background-color: #f5f5f5;
    transition: 0.3s ease-in-out;
}

.step.active {
    background-color: #ffa408;
    color: #fff;
}

.steps2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: absolute;
    width: 90vw;
}

.stageName {
    width: 10rem;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    margin-bottom: 2rem;
    transition: 0.3s ease-in-out;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(228, 227, 227, 0);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: rgb(174, 174, 174); */
  background: rgba(197, 197, 197, 0.886);
  /* background-color: rgb(203, 203, 203); */
  border-radius: 10px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(116, 116, 116);
}

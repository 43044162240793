.settingsNav {
    background-color: #fff;
    margin: auto 3rem;
    height: 50%;
    padding: 0.5rem 0;
    position: fixed;
    top: 0;
    margin-top: 10rem;
    border: none;
    border-radius: 10px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    box-shadow: 0px 0px 10px 2px #9e8989;
    z-index: 999;
    transition: "all 0.3s ease-in-out"
}

.settingsNavItems li{
    list-style-type: none;
    /* margin-bottom: ; */
    padding: 0.2rem;
    border-bottom: 1px solid gray;
}